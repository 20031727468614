<template>
  <div>
<!--    <dialog-instructors/>-->
<!--    <dialog-lesson-plans/>-->
<!--    <dialog-evaluation-plans/>-->
    <CRow>
      <CCol>
        <CCard>
<!--          <CCardHeader color="primary">-->
<!--            <CRow>-->
<!--              <CCol class="text-center text-white ">-->
<!--                <h4>{{ onCheck(fields.title) }}</h4>-->
<!--              </CCol>-->
<!--            </CRow>-->
<!--          </CCardHeader>-->
          <CCardBody>
            <CRow>
              <CCol col="12">
                <CSelect :label="onCheck(fields.schoolName)"
                         :disabled="disable"
                         :horizontal="{label: 'col-sm-3', input: 'col-sm-9'}"
                         @update:value="onSelectCampus"
                         :options="onCheck(campus)"/>
              </CCol>
              <CCol col="12">
                <CSelect :label="onCheck(fields.facultys)"
                         :disabled="disable"
                         :horizontal="{label: 'col-sm-3', input: 'col-sm-9'}"
                         @update:value="onSelectFacultys"
                         :options="onCheck(facultys)"/>
              </CCol>
              <CCol col="12">
                <CSelect :label="this.onCheck(fields.departments)"
                         :disabled="disable"
                         :horizontal="{label: 'col-sm-3', input: 'col-sm-9'}"
                         @update:value="onSelectDepartments"
                         :options="onCheck(departments)"/>
              </CCol>


            </CRow>

            <CTabs :active-tab.sync="activeTab">

              <CTab active>
                <template slot="title">
                  <CIcon name="cil-school"/>
                  1 General Infomation
                </template>
                <CCardBody border-color="light">
                  <generallnfimation/>
                </CCardBody>
              </CTab>

              <CTab>
                <template slot="title">
                  <CIcon name="cil-sitemap"/>
                  2 Course Syllabus
                </template>
                <CCardBody border-color="light">
                  <course-syllabus/>
                </CCardBody>
              </CTab>
              <CTab>
                <template slot="title">
                  <CIcon name="cil-library"/>3 Development of Students Learning Outcome
                </template>
                <development/>
              </CTab>
              <CTab>
                <template slot="title">
                  <CIcon name="cil-layers"/>4 Lesson Plan and Evaluation
                </template>
                <lesson-plan/>
              </CTab>
              <CTab>
                <template slot="title">
                  <CIcon name="cil-chart-pie"/>5 References
                </template>
                <CCardBody border-color="light">
                  <references/>
                </CCardBody>
              </CTab>
              <CTab>
                <template slot="title">
                  <CIcon name="cil-settings"/>6 Evaluation and Improvement of Course Operations
                </template>
                <evaluation-and-improvement/>
              </CTab>
            </CTabs>

          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import Generallnfimation from "@/project/views/pages/sub/GeneralInfomation";
import EvaluationAndImprovement from "@/project/views/pages/sub/EvaluationAndImprovement";
import CourseSyllabus from "@/project/views/pages/sub/CourseSyllabus";
import References from "@/project/views/pages/sub/References";
import Development from "@/project/views/pages/sub/Development";
import LessonPlan from "@/project/views/pages/sub/LessonPlan";
import DialogInstructors from "@/project/views/pages/dialog/DialogInstructors";
import DialogLessonPlans from "@/project/views/pages/dialog/DialogLessonPlans";
import DialogEvaluationPlans from "@/project/views/pages/dialog/DialogEvaluationPlans";

export default {
  name: 'CourseSpceification',
  components: {
    DialogEvaluationPlans, DialogLessonPlans, DialogInstructors, LessonPlan, Development, References, CourseSyllabus, EvaluationAndImprovement, Generallnfimation
  },
  props: {
    disable: {
      type: Boolean,
      default: false
    },
  },

  data: function () {
    return {

      activeTab: 0,
      horizontal: {label: 'col-3', input: 'col-9'},
      fields: {
        title: [
          {
            key: "th",
            value: "รายละเอียดของรายวิชา (มศอ 3)"
          },
          {
            key: "en",
            value: "Course Spceification TQF 3"
          }
        ],

        schoolName: [
          {
            key: "th",
            value: "ชื่อสถาบันอุดมศึกษา"
          },
          {
            key: "en",
            value: "Name of University"
          }
        ],
        facultys: [
          {
            key: "th",
            value: "สำนักวิชา"
          },
          {
            key: "en",
            value: "School"
          }
        ],
        departments: [
          {
            key: "th",
            value: "สาขาวิชา"
          },
          {
            key: "en",
            value: "Program"
          }
        ],
      },

      data: {
        campus: {},

        facultys: {},
        department: {},
        academicYear: null,
        semester: null,
        startDate: null,
        endDate: null,
      }

    }
  },
  mounted() {

  },

  created() {
    this.onInit()
  },

  beforeDestroy() {

  },

  methods: {

    onCheck(key) {
      for (var i = 0; i < key.length; i++) {
        if (key[i].key == this.lang) {
          return key[i].value;
        } else {
          if (key[i].key == "ch") {
            return key[i].value;
          }
        }
      }
    },

    //
    onInit() {

      this.$store.dispatch("course/onGetCampus")

      // this.$store.dispatch("course/onGetFacultys")
      // this.$store.dispatch("course/onGetCourses")

      // this.$store.dispatch("course/onGetTypes")



    },

    onSelectCampus(value, e) {
      this.data.campus = value;
      this.$store.dispatch("course/onGetFacultys", this.data.campus._id)


      var obj = {};
      obj.campus = this.data.campus._id

      this.$store.dispatch("course/onGetOperations",obj)
      this.$store.dispatch("course/onGetVerifications",obj)

    },

    onSelectFacultys(value, e) {
      this.data.facultys = value;
      this.$store.dispatch("course/onGetDepartment", this.data.facultys._id)
    },

    onSelectDepartments(value, e) {
      this.data.department = value;
      this.onLoadProgram()

    },

    onLoadProgram() {
      var body = {};
      body.campusId = this.data.campus._id
      body.facultyId = this.data.facultys._id
      body.departmentId = this.data.department._id

      this.$store.dispatch("course/onGetPrograms", body)

      this.$store.dispatch("course/onGetCategorys", body)
    }


  },

  computed: {
    ...mapGetters({

      lang: "setting/objLang",

      campus: "course/objCampus",
      facultys: "course/objFacultys",
      departments: "course/objDepartments",


      courses: "course/objCourses",
      program: "course/objProgram",
      type: "course/objType",

    })
  },

  watch: {
    campus(value) {
      this.data.campus = this.onCheck(value)[0].value
      this.$store.dispatch("course/onGetFacultys", this.data.campus._id)
      this.$store.dispatch("course/onGetCourses", this.data.campus._id)


      var obj = {};
      obj.campus = this.data.campus._id

      this.$store.dispatch("course/onGetOperations",obj)
      this.$store.dispatch("course/onGetVerifications",obj)
    },

    facultys(value) {
      this.data.facultys = this.onCheck(value)[0].value
      this.$store.dispatch("course/onGetDepartment", this.data.facultys._id)
    },

    departments(value) {
      this.data.department = this.onCheck(value)[0].value
      this.onLoadProgram()

    }
  }
}
</script>
