<template>
  <div>
    <CRow>
      <CCol>
        <!--        <course-spceification/>-->


        <CCard>
          <CCardHeader color="primary">
            <CRow>
              <CCol class="text-center text-white ">
                <h4>{{ onCheck(fields.title) }}</h4>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CTabs :active-tab.sync="activeTab" fill>

              <CTab active>
                <template slot="title">
                  <CIcon name="cil-clipboard"/>
                  {{ onCheck(fields.tqf3) }}
                </template>
                <course-spceification/>
              </CTab>

              <CTab>
                <template slot="title">
                  <CIcon name="cil-clipboard"/>
                  {{ onCheck(fields.tqf4) }}
                </template>
                <CCardBody border-color="light">
                </CCardBody>
              </CTab>

              <CTab>
                <template slot="title">
                  <CIcon name="cil-clipboard"/>
                  {{ onCheck(fields.tqf5) }}
                </template>
                <CCardBody border-color="light">
                </CCardBody>
              </CTab>

              <CTab>
                <template slot="title">
                  <CIcon name="cil-clipboard"/>
                  {{ onCheck(fields.tqf6) }}
                </template>
                <CCardBody border-color="light">
                </CCardBody>
              </CTab>
            </CTabs>
          </CCardBody>
        </CCard>
      </CCol>

    </CRow>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import CourseSpceification from "@/project/containers/CourseSpceification";

export default {
  name: 'Banks',
  components: {CourseSpceification},
  data: function () {
    return {
      activeTab: 0,
      lang: "th",
      fields: {
        title: [
          {
            key: "th",
            value: "รายละเอียดของรายวิชา (มศอ)"
          },
          {
            key: "en",
            value: "Course Spceification TQF"
          }
        ],
        tqf3: [
          {
            key: "th",
            value: "รายละเอียดของรายวิชา (มศอ 3)"
          },
          {
            key: "en",
            value: "Course Spceification TQF 3"
          }
        ],
        tqf4: [
          {
            key: "th",
            value: "รายละเอียดของรายวิชา (มศอ 4)"
          },
          {
            key: "en",
            value: "Course Spceification TQF 4"
          }
        ],
        tqf5: [
          {
            key: "th",
            value: "รายละเอียดของรายวิชา (มศอ 5)"
          },
          {
            key: "en",
            value: "Course Spceification TQF 5"
          }
        ],
        tqf6: [
          {
            key: "th",
            value: "รายละเอียดของรายวิชา (มศอ 6)"
          },
          {
            key: "en",
            value: "Course Spceification TQF 6"
          }
        ],
      }
    }
  },
  mounted() {

  },

  created() {
  },

  beforeDestroy() {

  },

  methods: {
    onCheck(key) {
      for (var i = 0; i < key.length; i++) {
        if (key[i].key == this.lang) {
          return key[i].value;
        } else {
          if (key[i].key == "ch") {
            return key[i].value;
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters({})
  },

  watch: {}
}
</script>
